<template>

  <div>
    <category-list-add-new
        :is-add-new-category-sidebar-active.sync="isAddNewCategorySidebarActive"
        @refetch-data="refetchData"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                  v-model="searchQuery"
                  class="d-inline-block mr-1"
                  placeholder="Search By Title"
              />
              <b-button
                  variant="primary"
                  @click="isAddNewCategorySidebarActive = true"
              >
                <span class="text-nowrap">Add Category</span>
              </b-button>
            </div>
          </b-col>

        </b-row>

      </div>

      <b-table
        ref="refCourseCategoriesListTable"
        class="position-relative"
        :items="fetchCategories"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
                :id="`edit-row-${data.item.id}-send-icon`"
                icon="EditIcon"
                class="cursor-pointer"
                size="16"
                @click="$router.push({name : 'dashboard-course-categories-edit', params: {id: data.item.id}})"
            />
            <b-tooltip
                title="Edit"
                class="cursor-pointer"
                :target="`edit-row-${data.item.id}-send-icon`"
            />

            <feather-icon
                :id="`trash-row-${data.item.id}-send-icon`"
                icon="TrashIcon"
                style="margin-left: 1rem!important;"
                @click="deleteItem(data.item.id)"
                class="cursor-pointer"
                size="16"
            />
            <b-tooltip
                title="Delete"
                class="cursor-pointer"
                :target="`trash-row-${data.item.id}-send-icon`"
            />
          </div>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,BTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { BFormRating } from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import CoursesListFilters from './CoursesListFilters.vue'
import CategoryListAddNew from '../courses-list/CategoryListAddNew'
import useCourseCategoriesList from './useCourseCategoriesList'
import courseStoreModule from '../courseStoreModule'
import CourseListAddNew from './CourseListAddNew.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {useToast} from 'vue-toastification/composition'

export default {
  data() {
    return {
      module_base_url: "courses",
      module_be_base_url: "courses",
      specialities_options: [],
      lists: [],
    }
  },
  components: {
    CoursesListFilters,
    CourseListAddNew,
    BFormRating,
    BTooltip,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    CategoryListAddNew,

    vSelect,
  },
  methods:{
    deleteItem(id){
      this.$swal({
        title: 'Are you sure?',
        text: "By deleting this category you will also delete all notes,lectures,flashcards and questions assigned to it!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.onDelete(id)
          this.$swal({
            icon: 'success',
            title: 'Deleted!',
            text: 'Category has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    }
  },
  setup() {
    const COURSE_APP_STORE_MODULE_NAME = 'courses'

    const toast = useToast()

    // Register module
    if (!store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.registerModule(COURSE_APP_STORE_MODULE_NAME, courseStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(COURSE_APP_STORE_MODULE_NAME)) store.unregisterModule(COURSE_APP_STORE_MODULE_NAME)
    })

    const isAddNewCategorySidebarActive = ref(false)

    const onDelete = (courseCategoryId) => {

      store.dispatch('courses/deleteCourseCategory', courseCategoryId)
          .then(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Category Deleted Successfully',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })
            refetchData()
          })
    }

    const roleOptions = [
      { label: 'Admin', value: 'admin' },
      { label: 'Author', value: 'author' },
      { label: 'Editor', value: 'editor' },
      { label: 'Maintainer', value: 'maintainer' },
      { label: 'Subscriber', value: 'subscriber' },
    ]

    const planOptions = [
      { label: 'Basic', value: 'basic' },
      { label: 'Company', value: 'company' },
      { label: 'Enterprise', value: 'enterprise' },
      { label: 'Team', value: 'team' },
    ]

    const statusOptions = [
      { label: 'Active', value: '1' },
      { label: 'Inactive', value: '0' },
    ]

    const verifiedOptions = [
      { label: 'Phone Verified', value: '1' },
      { label: 'Phone Not Verified', value: '0' },
    ]

    const {
      fetchCategories,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      refCourseCategoriesListTable,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      resolveUserVerifiedVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      verifiedFilter,
      statusFilter,
      specialityFilter
    } = useCourseCategoriesList()

    return {

      // Sidebar
      isAddNewCategorySidebarActive,

      fetchCategories,
      onDelete,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      refCourseCategoriesListTable,
      resolveUserStatusVariant,
      resolveUserVerifiedVariant,

      roleOptions,
      planOptions,
      statusOptions,
      verifiedOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      verifiedFilter,
      statusFilter,
      specialityFilter
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
